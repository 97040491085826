import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClassOverviewService } from '../../../services/class-overview.service';
import { catchError, Observable, of, tap } from 'rxjs';
import { Store } from 'devextreme/data';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxDataGridComponent } from 'devextreme-angular';
import mixpanel from 'mixpanel-browser';
import { InitialLoadService } from 'src/app/services/initial-load.service';
import { SpinnerService } from '@wilson/wilsonng';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Wilson } from 'src/def/Wilson';
import BenchmarkScoresViewModel = Wilson.BenchmarkScoresViewModel;
import GetClassOverviewResponse = Wilson.GetClassOverviewResponse;
import BaseResult = Wilson.BaseResult;
import HubUnitWeeks = Wilson.HubUnitWeeks;
import Student = Wilson.Student;
import TestDefinition = Wilson.TestDefinition;
import GetClassOverviewCsvExport = Wilson.GetClassOverviewCsvExport;
import WilsonProgram = Wilson.WilsonProgram;
import { exportDataGrid } from 'devextreme/excel_exporter';
import 'regenerator-runtime';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { DevExtremeHelper } from 'src/app/helpers/devExtremeHelper';
import { UserService } from 'src/app/services/user.service';

@Component({
  templateUrl: './class-overview.component.html',
  styleUrls: ['./class-overview.component.scss'],
})
export class ClassOverviewComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  store: Store;
  existingStudentIds: string[] = [];
  showScoresModal = false;
  selectedStudent: SelectedStudent;
  testDefinitions: TestDefinition[];
  classOverview: GetClassOverviewResponse;
  classId: string;
  benchmarkScores: BenchmarkScoresViewModel;
  isPersonalTenant: boolean;

  isEditing: boolean;
  addingExistingStudent: boolean;
  years: { label: string; value: number }[];

  editForm: FormGroup<{
    name: FormControl<string>;
    year: FormControl<number>;
  }>;

  options = {
    scales: {
      y: {
        stacked: true,
        min: 0,
        max: 100,
      },
      x: {
        stacked: true,
      },
    },
  };

  addNewStudentOptions: MenuItem[] = [
    {
      label: 'Add Existing Student',
      icon: 'pi pi-user-plus',
      command: () => (this.addingExistingStudent = true),
    },
  ];

  exportCsvOptions: MenuItem[] = [
    {
      label: 'Export Raw CSV',
      icon: 'pi pi-file',
      command: () => {
        this.getClassOverviewCsvExport().subscribe(() => {
          this.exportToCsv();
        });
      },
    },
  ];

  columns: { dataField: string; caption: string }[] = [
    { dataField: 'level', caption: 'Level' },
    { dataField: 'className', caption: 'Class Name' },
    { dataField: 'teacherName', caption: 'Teacher Name' },
    { dataField: 'studentName', caption: 'Student Name' },
    { dataField: 'unitName', caption: 'Unit Name' },
    { dataField: 'unitScore', caption: 'Unit Score' },
  ];

  csvDataSource: GetClassOverviewCsvExport[];

  constructor(
    private router: Router,
    private classOverviewService: ClassOverviewService,
    private route: ActivatedRoute,
    private initialLoadService: InitialLoadService,
    private spinnerService: SpinnerService,
    private confirmationService: ConfirmationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.benchmarkScores = this.initialLoadService.initialLoad.benchmarkScores;
    this.classId = this.route.snapshot.params['classId'];
    this.isPersonalTenant = this.userService.user['isPersonalTenant'];
    this.getClassOverview().subscribe();
    this.setupStore();
    mixpanel.track('Go To Class Overview');
  }

  initForm(): void {
    this.populateSchoolYears();
    this.editForm = this.formBuilder.group({
      name: [this.classOverview.name, Validators.required],
      year: [this.classOverview.startingYear, Validators.required],
    });
  }

  populateSchoolYears(): void {
    this.years = [-2, -1, 0, 1, 2]
      .map((offset) => this.classOverview.startingYear + offset)
      .map((year) => ({
        label: `${year}-${year + 1}`,
        value: year,
      }));
  }

  getClassOverview(): Observable<GetClassOverviewResponse> {
    this.spinnerService.show();
    return this.classOverviewService.getClassOverview(this.classId).pipe(
      tap((classOverview: GetClassOverviewResponse) => {
        this.classOverview = classOverview;
        this.testDefinitions =
          this.initialLoadService.initialLoad.testDefinitions.filter(
            (def) => def.programId === classOverview.level
          );
        this.initForm();
        this.spinnerService.hide();
      })
    );
  }

  /* istanbul ignore next */
  setupStore(): void {
    this.store = createStore({
      key: 'id',
      loadUrl: `classOverview/GetClassOverviewDataTable/${this.classId}`,
      insertUrl: `classOverview/AddStudent/${this.classId}`,
      updateUrl: `classOverview/UpdateStudent/`,
      deleteUrl: `classOverview/RemoveStudent/${this.classId}`,
      onModified: () => this.onModified(),
      onRemoved: () => this.onRemoved(),
      onInserted: () => this.onInserted(),
      onBeforeSend: (e, ajaxOptions: any) =>
        DevExtremeHelper.onBeforeSend(e, ajaxOptions),
    });
  }
  onModified(): void {
    this.getClassOverview().subscribe();
  }

  /*istanbul ignore next*/
  onRemoved(): void {
    mixpanel.track('Student Removed from Class', { classId: this.classId });
  }

  /*istanbul ignore next*/
  onInserted(): void {
    mixpanel.track('Student Added to Class', { classId: this.classId });
  }

  getStudentName(data: Student) {
    return `${data.firstName} ${data.lastName || ''}`.trim();
  }

  /*istanbul ignore next*/
  exportToXlsx(): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      `${this.classOverview.name} Roster`
    );
    // name plus test definitions
    worksheet.columns = [
      { width: 30 },
      ...this.testDefinitions.map((def) => ({ width: 20 })),
    ];

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet,
      customizeCell: ({ gridCell, excelCell }) => {
        // if this cell has a testId, then distill the object down to just the highest value
        if (gridCell?.value?.testId) {
          excelCell.value = gridCell.value.highestScoreAchieved;
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          `${this.classOverview.name} Roster.xlsx`
        );
      });
    });
    mixpanel.track('Datagrid Exported to .xlsx');
  }

  /*istanbul ignore next*/
  exportToCsv(): void {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(
      `${this.classOverview.name} Roster`
    );

    // Set up the columns in the worksheet
    this.columns.forEach((column, index) => {
      worksheet.getCell(1, index + 1).value = column.caption;
    });

    // Fill the data in the worksheet
    this.csvDataSource.forEach((dataItem, rowIndex) => {
      this.columns.forEach((column, colIndex) => {
        worksheet.getCell(rowIndex + 2, colIndex + 1).value =
          dataItem[column.dataField];
      });
    });

    // Save the workbook as a Blob
    workbook.csv.writeBuffer().then((buffer: ArrayBuffer) => {
      const data = new Blob([buffer]);
      saveAs(data, `${this.classOverview.name}Roster.csv`);
    });
  }
  getClassOverviewCsvExport(): Observable<GetClassOverviewCsvExport[]> {
    return this.classOverviewService
      .getClassOverviewCsvExport(this.classId)
      .pipe(
        tap((csvDataSource) => {
          this.csvDataSource = csvDataSource;
        })
      );
  }
  onContentReady(): void {
    this.existingStudentIds = this.dataGrid.instance
      .getDataSource()
      .items()
      .map((student: Student) => student.id);
  }

  selectStudent(student: Student): void {
    this.store.insert(student);
    this.addingExistingStudent = false;
    void this.dataGrid.instance.refresh();
  }

  btnViewDetails(event: Event, testId: string): void {
    const testIdNum = parseInt(testId);
    event.stopPropagation();
    // verify that the dataset has completed tests before we route
    const hasScores = this.dataGrid.instance
      .getDataSource()
      .items()
      .some((row) => row[testId]['highestScoreAchieved'] !== null);

    if (hasScores) {
      this.viewDetails(testIdNum);
    } else {
      this.viewDetailsNoData(testIdNum);
    }
  }

  onChartDataSelect(index: number): void {
    this.viewDetails(this.testDefinitions[index].id);
  }

  viewDetails(testId: number): void {
    void this.router.navigate(['/reports/unit', this.classId, testId]);
  }

  sortUnitTestColumn(
    value1: { highestScoreAchieved: number },
    value2: { highestScoreAchieved: number }
  ): number {
    // handle null values
    if (!value1.highestScoreAchieved && value2.highestScoreAchieved) return -1;
    if (!value1.highestScoreAchieved && !value2.highestScoreAchieved) return 0;
    if (value1.highestScoreAchieved && !value2.highestScoreAchieved) return 1;

    if (value1.highestScoreAchieved <= value2.highestScoreAchieved) return -1;
    if (value1.highestScoreAchieved > value2.highestScoreAchieved) return 1;
  }

  toggleScoresModal(
    name: string = null,
    id: string = null,
    scoreAttempts: ScoreAttempt[] = []
  ): void {
    this.selectedStudent = {
      name: name,
      id: id,
      scoreAttempts: scoreAttempts,
    };
    this.showScoresModal = !this.showScoresModal;
    if (this.showScoresModal) {
      mixpanel.track('Historic Scores Modal Launched');
    } else {
      mixpanel.track('Historic Scores Modal Closed');
    }
  }

  handleEdit(_: any, isEditing: boolean): void {
    this.isEditing = isEditing;

    if (!isEditing) {
      if (this.editForm.pristine || !this.editForm.valid) return;

      this.classOverview = undefined;
      this.spinnerService.show();

      const editChanges = {
        name: this.editForm.value.name,
        startingYear: this.editForm.value.year,
      };

      this.classOverviewService
        .updateClass(this.classId, editChanges)
        .pipe(
          tap((result: BaseResult) => {
            if (result) {
              const key = Object.keys(result)[0];
              this.messageService.add({
                severity: 'error',
                summary: `Invalid Form: ${key}`,
                detail: result[key],
              });
            } else {
              this.messageService.add({
                summary: 'Saved',
                detail: 'Your changes have been saved!',
                severity: 'success',
              });
            }
            this.getClassOverview().subscribe();
          }),
          catchError(() => {
            this.messageService.add({
              summary: 'Error',
              detail: 'Something went wrong. Please try again later.',
              severity: 'error',
            });
            this.getClassOverview().subscribe();
            return of(null);
          })
        )
        .subscribe();
    }
  }

  /* istanbul ignore next */
  addStudentRow(): void {
    void this.dataGrid.instance.addRow();
  }

  getTracking(_: number, hubUnitWeeks: HubUnitWeeks): HubUnitWeeks {
    return hubUnitWeeks;
  }

  deleteClass() {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete "${this.classOverview.name}"? <br/> This will delete the class and all associated test data.`,
      header: `Delete Class`,
      icon: 'pi pi-trash',
      defaultFocus: 'close',
      acceptButtonStyleClass: 'p-button-danger',
      accept: () =>
        this.classOverviewService
          .deleteClass(this.classId)
          .pipe(tap(() => this.router.navigate(['/utt/classes'])))
          .subscribe(),
    });
  }

  viewDetailsNoData(testId: number) {
    const testName = this.testDefinitions.find(
      (test) => test.id === testId
    ).name;
    this.confirmationService.confirm({
      message: `This class currently has no test data to display for this unit.<br/>Data will become available once students have completed tests for this unit.`,
      header: `No data available for ${testName}`,
      icon: 'pi pi-file-edit',
      defaultFocus: 'close',
      rejectButtonStyleClass: 'd-none',
      acceptLabel: 'Ok',
    });
  }

  cancelAddExistingStudent(): void {
    this.addingExistingStudent = false;
  }

  protected readonly WilsonProgram = WilsonProgram;
}

interface SelectedStudent {
  name: string;
  id: string;
  scoreAttempts: ScoreAttempt[];
}

interface ScoreAttempt {
  attemptNumber: number;
  dateAttempted: Date;
  score: number;
}
