<div class="flex justify-content-between">
  <div>
    <p class="p-text-secondary mb-0">
      Fundations Individual Unit Test Tracker Level {{ programLevel }}
    </p>
    <h1 *ngIf="testTitle" class="mt-0">
      {{ testTitle }}
      <span *ngIf="isReview" class="review-header"> (Review) </span>
    </h1>
  </div>
  <div *ngIf="!unitTest?.readOnly">
    <button
      (click)="startEdit()"
      *ngIf="isReview && !isRetest"
      class="p-button-outlined mr-2"
      label="Edit This Attempt"
      pButton
      data-automationId="edit_attempt_button"
    ></button>
    <button
      (click)="startRetest()"
      *ngIf="isReview && !isRetest"
      label="Start Re-Test"
      pButton
    ></button>
  </div>
</div>

<div class="card card-cluster">
  <div *ngIf="unitTest?.testResponse" class="card mt-3">
    <div class="grid flex-column lg:flex-row mt-0">
      <div class="col lg:col-4">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">Student</span>
          <input
            disabled
            pInputText
            type="text"
            value="{{ unitTest.testResponse.studentName }}"
          />
        </div>

        <div class="p-inputgroup mt-3">
          <span class="p-inputgroup-addon">Date</span>
          <input
            [disabled]="isReview && !isRetest"
            (ngModelChange)="unitTest.testResponse.testDate = $event"
            [ngModel]="unitTest.testResponse.testDate | date : 'yyyy-MM-dd'"
            pInputText
            type="date"
          />
        </div>

        <div class="p-inputgroup mt-3">
          <span class="p-inputgroup-addon">Attempt</span>
          <input
            [value]="unitTest.testResponse.attemptNumber"
            disabled
            pInputText
            type="number"
          />
        </div>
        <div *ngIf="isReview && !isRetest" class="p-inputgroup mt-3">
          <span class="p-inputgroup-addon">Grade</span>
          <input
            [value]="unitTest.testResponse.score"
            disabled
            pInputText
            type="number"
          />
        </div>
      </div>
      <div class="col">
        <label class="fw-bold" for="notes">Teacher Notes</label>
        <textarea
          data-automationId="teacher_notes_textarea"
          [(ngModel)]="unitTest.testResponse.teacherNotes"
          [disabled]="isReview && !isRetest"
          class="w-100"
          id="notes"
          pInputTextarea
        >
        </textarea>
      </div>
    </div>
  </div>

  <div *ngIf="unitTest?.testCategories?.length" class="card">
    <p class="p-text-secondary">
      Proceed through all of the questions below and click to mark any that your
      student responds to incorrectly.
    </p>

    <div class="my-3">
      <div
        *ngFor="
          let category of unitTest.testCategories;
          trackBy: getCategoryName
        "
        class="mb-4"
      >
        <h4>{{ category.name }}</h4>
        <div class="flex flex-wrap gap-3">
          <div
            *ngFor="let question of category.questions; trackBy: getQuestionId"
          >
            <p-toggleButton
              [(ngModel)]="question.correctResponse"
              [disabled]="isReview && !isRetest"
              offIcon="pi pi-times"
              onIcon="pi pi-check"
            ></p-toggleButton>
            <span *ngIf="question.text" class="question-text">
              {{ question.text }}
            </span>
            <img
              *ngIf="question.image"
              alt="image for {{ question.text }}"
              class="question-image"
              src="{{ '../../../assets/img/questions/' + question.image }}"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <p-button
      data-automationId="submit_unit_test_button"
      (click)="submitTest()"
      *ngIf="!(isReview && !isRetest)"
      [label]="isRetest ? 'Submit Re-Test' : 'Submit Unit Test'"
      class="mt-2 align-self-start"
    ></p-button>

    <button
      (click)="cancelAttempt()"
      *ngIf="!(isReview && !isRetest)"
      class="p-button-danger mt-2 ml-2 align-self-end"
      label="Cancel"
      pButton
    ></button>
  </div>
</div>
